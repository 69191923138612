@use "variables" as *;

/**
 * Syntax highlighting styles
 */
.highlight {
  background: $background-color-dark;
  @extend %vertical-rhythm;

  .highlighter-rouge & {
    background: $background-color-dark;
  }


  pre { background-color: $background-color-dark; }
  .hll { background-color: $background-color-dark; }
  .c { color: #75715e } /* Comment */
  .err { color: #960050; background-color: #1e0010 } /* Error */
  .k { color: #66d9ef } /* Keyword */
  .l { color: #ae81ff } /* Literal */
  .n { color: #f8f8f2 } /* Name */
  .o { color: #f92672 } /* Operator */
  .p { color: #f8f8f2 } /* Punctuation */
  .cm { color: #75715e } /* Comment.Multiline */
  .cp { color: #75715e } /* Comment.Preproc */
  .c1 { color: #75715e } /* Comment.Single */
  .cs { color: #75715e } /* Comment.Special */
  .ge { font-style: italic } /* Generic.Emph */
  .gs { font-weight: bold } /* Generic.Strong */
  .kc { color: #66d9ef } /* Keyword.Constant */
  .kd { color: #66d9ef } /* Keyword.Declaration */
  .kn { color: #f92672 } /* Keyword.Namespace */
  .kp { color: #66d9ef } /* Keyword.Pseudo */
  .kr { color: #66d9ef } /* Keyword.Reserved */
  .kt { color: #66d9ef } /* Keyword.Type */
  .ld { color: #e6db74 } /* Literal.Date */
  .m { color: #ae81ff } /* Literal.Number */
  .s { color: #e6db74 } /* Literal.String */
  .na { color: #a6e22e } /* Name.Attribute */
  .nb { color: #f8f8f2 } /* Name.Builtin */
  .nc { color: #a6e22e } /* Name.Class */
  .no { color: #66d9ef } /* Name.Constant */
  .nd { color: #a6e22e } /* Name.Decorator */
  .ni { color: #f8f8f2 } /* Name.Entity */
  .ne { color: #a6e22e } /* Name.Exception */
  .nf { color: #a6e22e } /* Name.Function */
  .nl { color: #f8f8f2 } /* Name.Label */
  .nn { color: #f8f8f2 } /* Name.Namespace */
  .nx { color: #a6e22e } /* Name.Other */
  .py { color: #f8f8f2 } /* Name.Property */
  .nt { color: #f92672 } /* Name.Tag */
  .nv { color: #f8f8f2 } /* Name.Variable */
  .ow { color: #f92672 } /* Operator.Word */
  .w { color: #f8f8f2 } /* Text.Whitespace */
  .mf { color: #ae81ff } /* Literal.Number.Float */
  .mh { color: #ae81ff } /* Literal.Number.Hex */
  .mi { color: #ae81ff } /* Literal.Number.Integer */
  .mo { color: #ae81ff } /* Literal.Number.Oct */
  .sb { color: #e6db74 } /* Literal.String.Backtick */
  .sc { color: #e6db74 } /* Literal.String.Char */
  .sd { color: #e6db74 } /* Literal.String.Doc */
  .s2 { color: #e6db74 } /* Literal.String.Double */
  .se { color: #ae81ff } /* Literal.String.Escape */
  .sh { color: #e6db74 } /* Literal.String.Heredoc */
  .si { color: #e6db74 } /* Literal.String.Interpol */
  .sx { color: #e6db74 } /* Literal.String.Other */
  .sr { color: #e6db74 } /* Literal.String.Regex */
  .s1 { color: #e6db74 } /* Literal.String.Single */
  .ss { color: #e6db74 } /* Literal.String.Symbol */
  .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
  .vc { color: #f8f8f2 } /* Name.Variable.Class */
  .vg { color: #f8f8f2 } /* Name.Variable.Global */
  .vi { color: #f8f8f2 } /* Name.Variable.Instance */
  .il { color: #ae81ff } /* Literal.Number.Integer.Long */

  .gh { } /* Generic Heading & Diff Header */
  .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
  .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
  .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */
}