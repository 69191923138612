@use "sass:color";

// Define defaults for each variable.
$heading-font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$heading-font-weight: 700 !default;

$base-font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     32px !default;

$text-color:       #e8e8e8 !default;
$background-color: #111418 !default;
$background-color-light: #e8e8e8 !default;
$background-color-dark: #0d0f12 !default;
$accent-color:      #61B3AF !default;

$grey-color-dark: #64748b !default;
$grey-color: color.scale($grey-color-dark, $lightness: 25%) !default;
$grey-color-light: color.scale($grey-color, $lightness: 40%) !default;

$table-text-align: left !default;

$content-width:    800px !default;
$on-palm:          600px !default;
$on-laptop:        800px !default;

// Common placeholder selectors
%vertical-rhythm {
  margin-bottom: $spacing-unit * 0.5;
}

%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
} 