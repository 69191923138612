@use "variables" as *;
@use "sass:color";

/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height}
        $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure {
    @extend %vertical-rhythm;
    margin-bottom: 1.5rem;
}

/**
 * `main` element
 */
main {
    display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images and media
 */
img, video {
    max-width: 100%;
    vertical-align: middle;
}

/**
 * Figures
 */
figure {
    margin: 2 * $spacing-unit auto;
}

figure > img {
    display: block;
}

figcaption {
    margin-top: 1em;
    font-size: $small-font-size;
    color: $grey-color;
}

/**
 * Lists
 */
ul,
ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
        margin-top: 1em;
        margin-bottom: 0;
    }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
}

/**
 * Links
 */
a {
    color: $accent-color;
    text-decoration: none;
    transition: 0.25s;

    &:visited {
        color: color.scale($accent-color, $lightness: -15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: none;
    }
}

/**
 * Blockquotes
 */
blockquote {
    margin: $spacing-unit auto;
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit * 0.5;
    @include relative-font-size(1.125);

    &.bigquote {
        border-left: none;
        margin: 2 * $spacing-unit auto;
        padding: 0 2 * $spacing-unit;

        @include relative-font-size(1.625);

        @include media-query($on-laptop) {
            @include relative-font-size(1.375);
        }
    }

    & cite {
        font-size: 1em;
        font-style: normal;
        color: $grey-color-light;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Code formatting
 */
pre,
code {
    @include relative-font-size(0.9375);
    border: 1px solid rgba($grey-color-dark, 0.5);
    border-radius: 4px;
    background-color: $background-color-dark;
}

code {
    padding: 2px 4px;
}

pre {
    margin: 0 0 1.5rem;
    padding: 8px 12px;
    overflow-x: auto;
    max-height: 50vh;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width: calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit * 0.5;
        padding-left: $spacing-unit * 0.5;
    }
}

/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$grey-color};
    padding-right: 5px;
    vertical-align: text-top;
}

/**
 * Tables
 */
table {
    margin-bottom: $spacing-unit;
    width: 100%;
    text-align: $table-text-align;
    color: color.scale($text-color, $lightness: 18%);
    border-collapse: collapse;
    border: 1px solid $grey-color-light;
    tr {
        &:nth-child(even) {
            background-color: color.scale($grey-color-light, $lightness: 6%);
        }
    }
    th,
    td {
        padding: ($spacing-unit * 0.3333333333) ($spacing-unit * 0.5);
    }
    th {
        background-color: color.scale($grey-color-light, $lightness: 3%);
        border: 1px solid color.scale($grey-color-light, $lightness: -4%);
        border-bottom-color: color.scale($grey-color-light, $lightness: -12%);
    }
    td {
        border: 1px solid $grey-color-light;
    }
}

/**
 * Dividers
 */
.divider-thin {
    border-bottom: 1px solid $grey-color-dark;
}

.divider-thin-light {
    border-bottom: 1px solid $grey-color-light;
}

.soft-break {
    margin: 2 * $spacing-unit auto;
    text-align: center;

    &::after {
        content: "— ◇ —";
        font-size: 1rem;
        color: $grey-color-dark;
        letter-spacing: 1em;
    }
}

/**
 * Utility
 */
.mt-50,
.mv-50 {
    margin-top: 0.5 * $spacing-unit;
}

.mb-50,
.mv-50 {
    margin-bottom: 0.5 * $spacing-unit;
}

.mt-100,
.mv-100 {
    margin-top: $spacing-unit;
}

.mb-100,
.mv-100 {
    margin-bottom: $spacing-unit;
}

.mt-200,
.mv-200 {
    margin-top: 2 * $spacing-unit;
}

.mb-200,
.mv-200 {
    margin-bottom: 2 * $spacing-unit;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.grid {
    display: grid;
    grid-gap: 32px;

    &.col2 {
        grid-template-columns: auto auto;
    }
    
    &.col3 {
        grid-template-columns: auto auto auto;
    }
}

.text-center {
    text-align: center;
}

.grey-color {
    color: $grey-color;
}

/**
 * Loading
 */
@keyframes skeleton-loading {
    0% {
        background-color: rgba($grey-color-dark, 0.08);
    }
    100% {
        background-color: rgba($grey-color-dark, 0.16);
    }
}

.lazy {
    animation: skeleton-loading 1s linear infinite alternate;
}
