@use "variables" as *;

/**
 * Page transitions
 */
html.is-changing .transition-fade {
    transition: opacity 0.25s;
    opacity: 1;
}
html.is-animating .transition-fade {
    opacity: 0;
}

/**
 * Site header
 */
.site-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $spacing-unit * 2;
    padding: 0 2rem;
    background-color: $background-color-light;

    @include media-query($on-laptop) {
        padding: 0 1rem;
    }
}

.site-title {
    display: flex;
    align-items: center;

    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.048em;

    &,
    &:visited {
        color: $background-color;
    }

    &:hover {
        color: $grey-color-dark;
        & .monogram {
            fill: $grey-color-dark;
        }
    }

    & .monogram {
        margin-right: 0.5rem;
        fill: $background-color;
        transition: 0.25s;
    }
}

.site-nav {
    position: relative;
    z-index: 10;

    .nav-trigger {
        display: none;
    }

    .menu-icon {
        display: none;
    }
    .trigger {
        display: flex;
        gap: 1.5rem;
    }

    .page-link {
        color: $background-color;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.024em;

        &:hover {
            color: $grey-color-dark;
        }

        &.current {
            position: relative;

            &::before {
                content: "/";
                position: absolute;
                left: -0.5rem;
                font-weight: 700;
                color: $grey-color;
            }
        }
    }

    @include media-query($on-palm) {
        background-color: $background-color-light;

        label[for="nav-trigger"] {
            display: flex;
            align-items: center;
            width: 2rem;
            height: 2rem;
            z-index: 2;
            cursor: pointer;
        }

        .menu-icon {
            display: flex;
            width: 2rem;
            height: 1.5rem;
            justify-content: center;
            align-items: center;

            > svg {
                fill: $background-color;
            }
        }

        input ~ .trigger {
            display: none;
        }

        input:checked ~ label[for="nav-trigger"] {
            background: $grey-color-light;
        }

        input:checked ~ .trigger {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: 0;
            padding: 1.5rem 2rem;
            gap: 1rem;
            background: $grey-color-light;
        }

        .page-link.current::before {
            color: $grey-color-dark;
        }
    }
}

/**
 * Hero sections
 */
.hero {
    height: calc(100vh - 2 * $spacing-unit);
}

.project-hero {
    height: calc(100vw * 81 / 256);
}

/**
 * Site footer
 */
.site-footer {
    background-color: $background-color-dark;
    padding: 1.5 * $spacing-unit 0 0.5 * $spacing-unit;
    @include relative-font-size(0.88);
    color: $grey-color;

    & h3 {
        margin-bottom: 0.5rem;
    }

    & .columns {
        display: flex;
        justify-content: space-between;

        @include media-query($on-palm) {
            flex-direction: column;
        }
    }

    & .footer-monogram {
        margin-bottom: 1.5rem;

        & .monogram {
            fill: $grey-color;
        }
    }
}

.footer-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
    line-height: 2;
}

/**
 * Page content
 */
.page-content {
    padding: 2.5 * $spacing-unit 0;
    flex: 1;

    &.project-content {
        padding-top: 0;
    }
}

.page-heading {
    @include relative-font-size(2);
}

.post-list-heading {
    @include relative-font-size(1.75);
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-link {
    display: block;
    @include relative-font-size(1.5);
}

/**
 * Posts
 */

.post-header {
    padding-bottom: $spacing-unit;
    border-bottom: 1px solid $grey-color-dark;
    margin-bottom: 2 * $spacing-unit;
}

.post-title,
.page-heading {
    @include relative-font-size(2.625);
    letter-spacing: -1px;
    line-height: 1.25em;
    max-width: 24ch;

    @include media-query($on-laptop) {
        @include relative-font-size(2.25);
    }
}

.post-meta {
    display: flex;
    flex-direction: column;
    gap: 0.5 * $spacing-unit;
    font-size: $small-font-size;
    color: $grey-color;
}

.byline {
    font-weight: 700;
    margin-bottom: 0;

    & p {
        display: inline;
    }
}

.post-summary > p {
    max-width: 64ch;
    margin: 0;
}

.post-tags {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0 1rem;
    gap: 1rem;

    & .tag {
        margin-bottom: 0;
    }

    & a {
        display: block;
        background-color: $grey-color-light;
        color: $background-color;
        padding: 1px 0.5em 2px;
        font-weight: 700;

        &:hover {
            background-color: $accent-color;
        }
    }
}

.post-content,
.page-content {
    margin-bottom: $spacing-unit;

    h2 {
        @include relative-font-size(2);

        @include media-query($on-laptop) {
            @include relative-font-size(1.75);
        }
    }

    h3 {
        @include relative-font-size(1.625);

        @include media-query($on-laptop) {
            @include relative-font-size(1.375);
        }
    }

    h4 {
        @include relative-font-size(1.25);

        @include media-query($on-laptop) {
            @include relative-font-size(1.125);
        }
    }

    li {
        margin-bottom: 1em;
    }

    figure {
        & img,
        & video {
            margin: 0 auto;
        }

        & .jekyll-twitter-plugin {
            display: flex;
            flex-grow: 1;
            justify-content: center;
        }

        & figcaption {
            text-align: center;
        }
    }
}

/**
 * Pagination
 */

.back-link::before {
    content: "\2190";
    flex-shrink: 0;
    margin-right: 0.5em;
}

.next-link::after {
    content: "\2192";
    flex-shrink: 0;
    margin-left: 0.5em;
}

.page-navigation {
    display: flex;
    justify-content: space-between;
    gap: $spacing-unit;

    & span {
        position: relative;
        max-width: calc(50% - 1.5em - 0.5*$spacing-unit);

        &.prev-page {
            padding-left: 1.5em;
            & .back-link {
                &::before {
                    position: absolute;
                    left: 0;
                }
            }
        }

        &.next-page {
            text-align: right;
            padding-right: 1.5em;
            & .next-link {
                &::after {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}
